<div class="data-body" [ngClass]="{'grupo': modeGroup}">

	<span *ngIf="modeGroup && cliente == null">
		<div class="card">
			<div class="card-title">
				<div class="title">
					<div class="saldo-total">Saldo total</div>
					<div class="circle">
						<img src="../../../../../../../assets/images/icons/balance/i.png" alt="img">
					</div>
				</div>
				<div class="cantidad-total">
					{{ saldo | currency:"EUR"}}
				</div>
			</div>
			<div class="card-content">
				<div class="body1">
					<div class="saldo-premios">
						Tu saldo en Grupo
					</div>
					<div class="cantidad-premio-saldo">
						{{ saldoMiembro | currency:"EUR"}}
					</div>
					<div class="boton"(click)="verSaldo()">
						Ver Saldo
					</div>
				</div>
				<div class="body2">
					<app-user-img *ngIf="imagen; else texto" [imagen]="imagen"></app-user-img>
					<ng-template #texto>
						<app-user-img [texto]="firstLetterName" [colorTexto]="nombreUser"></app-user-img>
					</ng-template>
				</div>
			</div>
		</div>
	</span>

	<span *ngIf="cliente != null || !modeGroup">
		<div class="balance-wrapper">
			<span class="balance-text">Saldo Total</span>
			<a class="balance-number">{{ saldo | currency:'EUR'}}</a>
			<div class="actions-wrapper">
				<button mat-raised-button class="retrieve-funds" (click)="retirarBtn()">Retirar</button>
				<button mat-raised-button class="add-funds" (click)="ingresarBtn()">Cargar</button>
			</div>
		</div>
	</span>

	<div class="transactions-wrapper">
		<span class="transactions-text">Actividad</span>
		<div class="horizontal" (mousedown)="onMouseDown($event)" (mouseleave)="onMouseLeave()" (mouseup)="onMouseUp()">
			<div class="scroll-wrapper">
				<div class="flex" #scrollContent>
					<div class="option" (click)="onCategoryClick(null)" [ngClass]="{'selected': null === selectedCategory}">Ver Todo</div>
					<div class="option" (click)="onCategoryClick('INGRESO')" [ngClass]="{'selected': 'INGRESO' === selectedCategory}">Ingresos</div>
					<div class="option" (click)="onCategoryClick('PAGO')" [ngClass]="{'selected': 'PAGO' === selectedCategory}">Pagos</div>
					<div class="option" (click)="onCategoryClick('COBRO')" [ngClass]="{'selected': 'COBRO' === selectedCategory}">Transferencias</div>
					<div class="option" (click)="onCategoryClick('INFORMACION')" [ngClass]="{'selected': 'INFORMACION' === selectedCategory}">Información</div>
					<div class="option" (click)="onCategoryClick('PROMOCION')" [ngClass]="{'selected': 'PROMOCION' === selectedCategory}">Promociones</div>
				</div>
				<div class="scroll-gradient"></div>
			</div>
		</div>
		<ng-scrollbar class="scroll" itemSize="20">
			<div *ngFor="let movimiento of actividad; let i = index" (click)="openSheet(movimiento)" class="transaction">
				<div class="transaction-header">
					<span class="transaction-type">{{ title[i] }}</span>
					<span class="account-total-number">{{ movimiento.saldo_total | currency:'EUR'}}</span>
				</div>
				<div class="transaction-body">
					<div class="transaction-date">{{movimiento.fecha | date:'dd/MM/Y | H:mm'}}</div>
					<div class="transaction-number" [ngClass]="{ingreso: !comprobarCobro(movimiento.importe), cobro: comprobarCobro(movimiento.importe)}">
						{{ movimiento.importe | currency:'EUR'}}
					</div>
				</div>
			</div>
		</ng-scrollbar>
	</div>
</div>