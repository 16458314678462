<div class="play-wrapper">
    <img *ngIf="!play.producto" class="play-game-icon" src="/assets/images/icons/plays-list/icons-list/icono_juego_{{play.idGame}}.png" (click)="detailPlay(play)"/>
    <img *ngIf="play.producto && play.miniatura" class="play-game-mini" src="https://www.loteriaelnegrito.com/uploads/{{play.miniatura.path}}{{play.miniatura.nombre}}" (click)="detailPlay(play)"/>
    <img *ngIf="play.producto && !play.miniatura" class="play-game-mini" src="/assets/images/icons/plays-list/icons-list/ticket.png" (click)="detailPlay(play)"/>
    <div class="play-details" (click)="detailPlay(play)">
        <div class="play-info" >
            <span *ngIf="play.producto; then producto else notProducto"></span>
            <ng-template #producto>
                <span class="play-game-name">Participación</span>
            </ng-template>
            <ng-template #notProducto>
                <ng-container *ngIf="play.nombre == null; then juego else nombre"></ng-container>
            </ng-template>
            <ng-template #juego>
                <span class="play-game-name">{{play.name}}</span>
            </ng-template>
            <ng-template #nombre>
                <span class="play-game-nombre">{{play.nombre}}</span>
            </ng-template>
			<span class="play-game-price">{{play.price}}€</span>
            <span class="play-game-status" [ngStyle]="styleState()">
                <span *ngIf="!isSorteado() && play.state != 3">¡En juego!</span>
                <span *ngIf="isSorteado() && play.state != 3">Sorteada</span>
                <span *ngIf="play.state == 3">¡Premiada!</span>
            </span>
			<img *ngIf="play.guinda" class="laguinda-icon" src="/assets/images/icons/play-detail/laguinda-icon.png">
        </div>

        <div class="play-bet" (click)="detailPlay(play)">


            <h4 class="bet-numbers">

                <!-- Apuesta Numeracion -->
                <span *ngFor="let number of play.betsNumbers[0]">

                    <span *ngIf="play.idGame == 6 || play.idGame == 7 || play.idGame == 8; then lottery else notLottery"></span>
                    <ng-template #lottery>
                        <span style="margin-right: 5px;">{{number}}</span>
                    </ng-template>
                    <ng-template #notLottery>
                        <div *ngIf="number < 10; then addZero else withoutZero"></div>

                        <ng-template #addZero>
                            <span style="margin-right: 5px;">0{{number}}</span>
                        </ng-template>

                        <ng-template #withoutZero>
                            <span style="margin-right: 5px;">{{number}}</span>
                        </ng-template>
                    </ng-template>
                </span>

                <!-- Apuesta Estrellas -->
                <span *ngIf="play.idGame == 3">
                    <span *ngFor="let number of play.valueStars[0].estrellas">

                        <div *ngIf="number < 10; then addZero else withoutZero"></div>

                        <ng-template #addZero>
                            <span>
                                <img class="bet-stars" src="/assets/images/icons/plays-list/star.svg"/>0{{number}}
                            </span>
                        </ng-template>

                        <ng-template #withoutZero>
                            <span>
                                <img class="bet-stars" src="/assets/images/icons/plays-list/star.svg"/>{{number}}
                            </span>
                        </ng-template>
                    </span>
                </span>

				<!-- Apuesta Estrellas -->
                <span *ngIf="play.idGame == 2">
                    <span *ngFor="let number of play.valueStars[0].estrellas">
                        🌸{{number}}
                    </span>
                </span>

                <!-- Apuesta Reintegro -->
                <span *ngIf="(play.idGame == 1 || play.idGame == 4 || play.idGame == 5) && !play.producto">

                    <span *ngIf="play.refund; then refund else waitRefund"></span>

                    <ng-template #refund>
                        <span>R: {{ play.refund }}</span>
                    </ng-template>
                    <ng-template #waitRefund>
                        <span>R: *</span>
                    </ng-template>

                </span>

                <span *ngIf="play.producto">
                    {{play.nombre_producto}}
                </span>

            </h4>
        </div>
    </div>
    <img class="image-status" src="/assets/images/icons/plays-list/prize_icon.svg" *ngIf="play.state == 3">
    <img class="image-status" src="/assets/images/icons/plays-list/sub_icon.svg" *ngIf="play.abono" [attr.title]="play.abono.aleatorio == 0 ? 'Suscripción' : 'Suscripción Aleatoria'">
    <img class="image-status" src="/assets/images/icons/plays-list/share_icon.svg" *ngIf="play.shared">
    <img class="image-status" src="/assets/images/icons/games/gift-free-bet.svg" *ngIf="play.jugada_regalada && play.jugada_regalada.aceptada != null">
    <img class="image-status gifted-play-status" src="/assets/images/icons/games/gift-free-bet-gifted.svg" *ngIf="play.jugada_regalada && play.jugada_regalada.aceptada == null">

    <img *ngIf="!play.producto" class="play-game-details" src="/assets/images/icons/play-detail/play-details.svg" (click)="openOptions()">
</div>
<div class="actions-play" *ngIf="selected">
    <div class="group-action" *ngIf="grupo == null && !play.guinda && play.state == 1 && play.jugada_regalada == null" (click)="compartir()">
        <img src="/assets/images/icons/play-detail/share-play.svg">
        <span class="action-name">Compartir</span>
    </div>
    <div class="group-action" *ngIf="!play.abono" (click)="abonarse()">
        <img src="/assets/images/icons/play-detail/abono-play.svg">
        <span class="action-name">Suscribrise</span>
    </div>
    <div class="group-action" *ngIf="play.abono" (click)="desabono()">
        <img src="/assets/images/icons/play-detail/abono-play-full.svg">
        <span class="action-name">Desuscribirse</span>
    </div>
	<div class="group-action" (click)='repeat()'>
        <img src="/assets/images/icons/play-detail/repeat-play.svg">
        <span class="action-name">Repetir</span>
    </div>
    <div class="group-action" *ngIf="grupo == null && !play.guinda && play.idGame == 6 && play.state == 1 && play.jugada_regalada == null && !play.link" (click)="openRegalarJugada()">
        <img src="/assets/images/icons/play-detail/gift.svg">
        <span class="action-name">Regalar</span>
    </div>
	<div class="group-action" *ngIf="grupo == null && guindaConfig && !play.guinda && play.idGame == 6 && play.state == 1 && play.jugada_regalada == null && play.shared == 0 && !play.link && !play.semanal && play.price > 0.5" (click)="openGuindaJugada()">
        <img src="/assets/images/icons/play-detail/ic_lock.svg" class="rojo">
        <span class="action-name">Asegurar</span>
    </div>
</div>
