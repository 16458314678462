export class MPlay {

    constructor(
        public id: number,
        public date: Date | String,
        public name: String,
        public image: String,
        public state: number,
        public betsNumbers: Array<Array<any>>,
        public valueStars: Array<number>,
        public refund: number,
        public shared: boolean,
        public paid: boolean,
        public price: string,
        public deliveryId: number,
        public idGame: number,
        public simulacion: any,
		public semanal?: boolean,
		public multiple?: boolean,
		public guinda_price?: number,
        public relacionadas?: any,
        public jugada_regalada?: any,
        public link?: any,
        public abono?: any,
        public dateSorteo?: any,
        public order?: any,
        public producto?: boolean,
        public miniatura?: any,
        public nombre_producto?: any,
        public valuesLottery?: any,
		public guinda? : any,
        public nombre?: string,
		public validated?: number
    ){}
}
