<div class="options-banner">

	<div class="scroll">
		<div class="flex">
			<div class="option" *ngFor="let option of FormasEnvio" (click)="selectOption(option)" [class.selected]="selectedOption === option">
				<div class="icon">
					<img [src]="'/assets/images/icons/formas-envio/forma-' + option.id + '.svg'"  
					onerror="this.onerror=null;this.src='/assets/images/icons/formas-envio/default.svg'" class="test">
				</div>
				<span class="option-text">{{option.nombre}}</span>
			</div>
		
			<div class="option" (click)="reservas()">
				<div class="icon">
					<img class="test" src="/assets/images/icons/formas-envio/reserva.svg">
				</div>
				<span class="option-text">Reserva</span>
			</div>
			<div class="option" (click)="empresas()">
				<div class="icon">
					<img class="test" src="/assets/images/icons/formas-envio/empresa.svg">
				</div>
				<span class="option-text">Empresa</span>
			</div>
		</div>
	</div>
</div>

<div class="help-banner" *ngIf="selectedOption && selectedOption.id === 4">
	<span class="help-text">Descubre como hacemos los envíos</span>
	<button class="help-button" (click)="onHelpClick()">Ayuda</button>
</div>

<div class="help-banner" *ngIf="selectedOption && selectedOption.id === 5">
	<span class="help-text">Descubre como hacer la recogida</span>
	<button class="help-button" (click)="onHelpClick()">Ayuda</button>
</div>